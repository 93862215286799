body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #525252;
  display: grid;
  place-items: center;
}

.loader-wrapper h1 {
  /* font-size: ; */
  color: #f7d4b1;
}

.loader {
  border: 16px solid #958787;
  border-top: 16px solid #f7d4b1;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
