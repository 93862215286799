.section__2 {
  padding-top: 9rem;
  padding-bottom: 8rem;
}
.section__2__bottom {
  padding-top: 1rem;
  padding-bottom: 6.25rem;
  
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.section__3__content{
  padding-inline: 20px;
  padding-top: 64px;
  padding-bottom: 120px;
}
.section__4__content {
  padding-inline: 20px;
  padding-top: 64px;
  padding-bottom: 0px;
}

.section__3__about__img {
  border-radius: 10px;
  position: relative;
}
.p-relative {
  position: relative;
}

.section__4__client__margin {
  margin-top: 7%;
}
.section__4__client_s {
  padding: 25px;
  border-radius: 20px;
}
.section__5 {
  padding: 50px;
  border-radius: 20px;
}
.section__5 p {
  font-size: 1.2rem;
}
.section__4__client__p,
.section__5 p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.8rem;
}
.section__6 .col-lg-12,
.section__6 .col-lg-11,
.section__7 .col-lg-12,
.section__8 .col-lg-12,
.section__8 .col-lg-11,
.section__7 .row_custom {
  border-radius: 20px;
}
.pe_lg {
  padding-right: 1.22rem !important;
}

.section__6 h5 {
  font-weight: 700;
}
.section__6 .img_round {
  border-radius: 20px;
}

.graph__h5 {
  /* top:0px; */
  /* left: 0px; */
}

.section__7__img {
  border-radius: 20px;
}

.code {
  border-radius: 20px;
}
.intro {
  display: flex;
  margin-top: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 80px;
  font-weight: 600;
  letter-spacing: -0.045em;
}
.anim {
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg);
  transform-style: preserve-3d;
  animation: fadeIn 0.5s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}
.anim .introMonth {
  position: relative;
  animation: opasitysAnimation 0.5s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}
@keyframes opasitysAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.15;
  }
}
@keyframes fadeIn {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-40px);
  }
}
.anim .introMonthLine {
  animation-name: widthBar;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 0.8s;
  animation-duration: 0.5s;
}
@keyframes widthBar {
  0% {
    width: 0%;
  }
  100% {
    width: 110%;
    position: absolute;
    top: 50%;
    height: 10px;
    margin-left: -5%;
    border-radius: 20px;
    background-color: #1d1d1f;
  }
}
.graph {
  margin-inline: auto;
}
.anim .introWeeks {
  background-image: linear-gradient(-70deg, #ed4e50, #9867f0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-name: opasityAnimation;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  animation-delay: 0.8s;
  opacity: 0;
}
@keyframes opasityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
.AddCircle {
  font-size: 40px;
  color: #949496;
  top: 10px;
  right: 29px;
  display: none;
}
.workPage:hover .AddCircle {
  display: block;
}

@media screen and (max-width: 512px) {
  .intro {
    display: flex;
    margin-top: 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 60px;
    font-weight: 600;
    letter-spacing: -0.045em;
  }
  .section__4__client_s {
    padding: 25px 0px;
    border-radius: 20px;
  }
  .home h4 {
    font-weight: 600;
  }
  .home-content {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .home h1 {
    text-align: center;
    font-weight: 600;
    font-size: 3rem;
  }
  .home h4 {
    font-size: 20px;
  }
  .home span {
    font-size: 18px;
  }
  .about-text {
    font-weight: 700;
  }
  .section__3__content h1,
  .section__4__content h1,
  .section__9 h1,
  .section__10 h2 {
    font-weight: 700;
    font-size: 3.1rem;
    margin-bottom: 64px;
  }

  .section__3__about {
    padding: 36px;
    border-radius: 20px;
  }
  .section__3__about .col-lg-6 {
    padding-bottom: 2rem;
  }
  .section__4__client {
    padding: 36px;
    border-radius: 20px;
  }
  .section__3__about h3 {
    font-weight: 600;
    font-size: 28px;
  }
  .section__3__about h3 .blue__color {
    font-weight: 600;
    font-size: 1rem;
  }
  .section__3__about p {
    font-weight: 500;
    line-height: 1.6;
  }
  .section__3__about__img {
    border-radius: 10px;
    width: 100%;
  }
  .image:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    border-radius: 10px;
    border: 4px solid #00aeef;
    top: 34px;
    left: 34px;
    border-radius: 10px;
    border: 4px solid #00aeef;
  }
  .section__4__client {
    padding: 36px;
    border-radius: 20px;
  }
  .section__4__client h3 {
    font-size: 1.75rem;
    font-weight: 600;
  }
  .section__4__client_s h1 {
    font-size: 3rem;
  }
  .section__5 {
    padding: 30px;
    border-radius: 20px;
  }
  .tv-img {
    width: 100%;
  }
  .section__5 h3 {
    font-weight: 600;
    font-size: 1.75rem;
  }
  .section__5 p {
    font-size: 1.125rem;
  }
  .video {
    width: 98%;
    left: 1% !important;
    top: 1.2% !important;
  }
  .graph {
    padding-inline: 20px;
    min-height: 218px;
  }
  .graph__h5 {
    padding-top: 2rem;
    font-size: 1.1rem;
  }
  .section__6__h5 {
    bottom: 0%;
    left: 28%;
  }
  .img__responsive {
    background-image: url("/public/Pixel_Background_(Smartphone).png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 30vh;
    border-radius: 20px;
  }
  .img__responsive h3 {
    font-weight: 700;
  }
  .responsive__h5 {
    top: 14px;
    left: 85px;
    font-weight: 700;
  }
  .section__6__h5 {
    /* width: 100%; */
    bottom: 0;
    font-size: 1.1rem;
    text-align: center;
  }
  .custom_pad {
    padding-inline: 0;
  }
  .g1 {
    padding-top: 2rem;
    width: 90%;
  }
  .section__9__grad h2 {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
  }
  .section__9__grad {
    background: linear-gradient(115deg, #e65160 0%, #a264df 74%);
    padding: 50px 20px;
    border-radius: 20px;
  }
  .question {
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
  }
}
@media screen and (min-width: 512px) {
  .section__9__grad {
    background: linear-gradient( 91.36deg, #eca658 0%, #f391a6 13.02%, #e188c3 25.52%, #a58de3 37.5%, #56abec 49.48%, #737eb7 63.02%, #c8638c 72.92%, #dd5d57 84.38%, #df6c51 97.92% );
    background-size: auto;
    background-clip: border-box;
  animation: gradient 10s ease infinite;
  background-size: 200% 200%;
  padding-bottom: 10px;
    padding: 50px;
    border-radius: 20px;
  }
  .section__9__grad h2 {
    font-weight: 700;
    font-size: 2.5rem;
    text-align: center;
  }
  .home h4 {
    font-weight: 600;
  }
  .home-content {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
  .home h1 {
    font-weight: bold;
    font-size: 3rem;
    padding-bottom: 0rem;
  }
  .about-text {
    font-weight: 700;
  }
  .section__3__content h1,
  .section__4__content h1 {
    font-weight: 700;
    font-size: 3rem;
  }
  .section__3__content h1 {
    font-weight: 600;
    font-size: 4rem;
  }
  .section__3__about {
    padding: 36px;
    border-radius: 20px;
    margin-top: 8%;
  }
  .section__3__about h3 {
    font-weight: 600;
  }
  .section__3__about h3 .blue__color {
    font-weight: 600;
    font-size: 1rem;
  }
  .section__3__about p {
    font-weight: 500;
    line-height: 1.3;
  }
  .section__3__about__img {
    border-radius: 10px;
    width: 60%;
    padding-bottom: 15%;
  }
  .image:before {
    position: absolute;
    width: 60%;
    height: 80%;
    content: "";
    display: block;
    border-radius: 10px;
    border: 4px solid #00aeef;
    top: 34px;
    left: 34px;
    border-radius: 10px;
  }
  .section__4__client {
    padding: 36px;
    border-radius: 20px;
  }
  .section__4__client h3 {
    font-size: 2rem;
    font-weight: 600;
  }
  .section__4__client_s h1 {
    font-size: 3rem;
  }
  .section__5 h3 {
    font-weight: 600;
    font-size: 2.2rem;
  }
  .video {
    width: 98%;
    left: 1% !important;
    top: 1.4% !important;
  }
  .graph {
    padding-left: 3rem;
    min-height: 600px;
  }
  .graph__h5 {
    padding-top: 2rem;
    font-size: 1.8rem;
  }
  .img__responsive {
    background-image: url("/public/Pixel_Background_(Smartphone).png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 30vh;
    border-radius: 20px;
  }
  .img__responsive h3 {
    font-weight: 700;
  }
  .responsive__h5 {
    top: 0px;
    left: 41px;
    font-weight: 700;
  }
  .section__6__h5 {
    width: 100%;
    bottom: 32px;
    text-align: center;
    font-size: 1.8rem;
  }
  .g1 {
    width: 50%;
    padding-top: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .home {
    height: 90%;
    width: 100%;
  }
  .home h4 {
    font-weight: 600;
  }

  .home-content {
    padding-top: 7rem;
    padding-bottom: 5rem;
  }
  .home h1 {
    font-weight: bold;
    font-size: 5rem;
  }
  .about-text {
    font-weight: 700;
  }
  .section__3__content h1,
  .section__4__content h1,
  .section__9 h1,
  .section__10 h2 {
    font-weight: 600;
    font-size: 5rem;
  }

  .section__3__about {
    padding: 36px;
    border-radius: 20px;
    margin-top: 10%;
  }

  .section__3__about h3,
  .section__4__content h3 {
    font-weight: 600;
  }
  .section__3__about h3 .blue__color {
    font-weight: 600;
    font-size: 1rem;
  }
  .section__3__about p {
    font-weight: 500;
    line-height: 1.8;
  }
  .section__3__about__img {
    border-radius: 10px;
    width: 350px;
    padding-bottom: 0%;
    filter: unset;
  }
  .section__3__about__img:hover {
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-190deg)
      saturate(600%) contrast(0,8);
  }
  .image:before {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    content: "";
    display: block;
    border-radius: 10px;
    border: 4px solid #00aeef;
    top: 34px;
    left: 34px;
    border-radius: 10px;
    border: 4px solid #00aeef;
  }
  .section__3__about__img:hover .image:before {
    position: absolute;
    width: 70%;
    height: 90%;
    max-width: 90%;
    content: "";
    display: block;
    border-radius: 10px;
    border: 4px solid #00aeef;
    top: 34px;
    left: 34px;
    border-radius: 10px;
    border: 4px solid #00aeef;
  }

  .section__4__client {
    padding: 36px;
    border-radius: 20px;
  }
  .section__4__client_s h1 {
    font-size: 3rem;
  }
  .tv-img {
    width: 100%;
  }
  .section__4__client_s {
    padding: 0px 9px;
    border-radius: 20px;
    margin: 0px 10px;
  }

  .video {
    width: 98%;
    left: 1.1% !important;
    top: 1.7% !important;
  }
  .graph {
    padding-left: 2rem;
    min-height: 290px;
  }

  .responsive__h5 {
    top: 41px;
    left: 44px;
    font-weight: 700;
  }
  .img__responsive {
    background-image: url("/public/Pixel_Background.png");
    background-size: cover, auto;
    background-repeat: no-repeat;
    background-position: center center;
    height: auto;
    border-radius: 20px;
  }
  .img__responsive h3 {
    font-weight: 700;
  }
  .responsive__h5 {
    top: 38px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 28px;
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: -0.025em;
    max-width: 200px;
  }
  .graph__h5 {
    padding-top: 3rem;
  }
  .section__6__h5,
  .graph__h5 {
    font-size: 24px;
  }
  .section__6__h5 {
    width: 100%;
    bottom: 16px;
    text-align: center;
  }
  .section__10 h2 {
    padding-block: 30px 30px;
  }
  .g1 {
    width: 62%;
    padding-top: 2rem;
  }
  .question {
    font-size: 1.1rem;
    font-weight: 600;
    text-align: start;
  }
}

.border__round {
  border-radius: 20px;
}

sub {
  font-size: 1.5rem;
}
.client,
.client sup {
  background-color: #726cf8;
  background: linear-gradient(315deg, #10dbbd 0%, #018b43 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.exprerience,
.exprerience sub {
  background-color: #726cf8;
  background: linear-gradient(10deg, #1277db 0%, #b4d3d6 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.prototype,
.prototype sup {
  background-color: #726cf8;
  background: linear-gradient(315deg, #553aed 0%, #b05ff8 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.profession,
.profession sub {
  background-color: #726cf8;
  background: linear-gradient(315deg, #f7a07b 0%, #ce528e 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ReactCardFlipFront {
  padding: 30px 20px;
  border-radius: 20px;
  min-height: 100px;
}
.ReactCardFlipBack {
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 0.9rem;
  text-align: start;
  height: auto;
  min-height: 6.25rem;
}

.g1 {
  transform-origin: bottom;
  animation: revealing-bars 4s linear forwards;
}
@keyframes revealing-bars {
  0% {
    transform: scaleY(0);
  }
  15% {
    transform: scaleY(1);
  }
}
.section__8 h6,
.section__8 h4 {
  font-size: 1.1rem;
  font-weight: 600;
}
/* Light Colors */
.light .ReactCardFlipFront {
  background-color: #fff;
}
.light .ReactCardFlipBack {
  background-color: #fff;
}
.light .section__3 {
  background-color: #b6d4d6;
}
.light .section__4 {
  background-color: #eeeef0;
}
.light .section__4__client__p {
  color: #57585a;
}
.light .section__4__client_s {
  background-color: #ffffff;
  color: #000;
}
.light .section__5 {
  background-image: linear-gradient(120deg, #eb4f56, #9d66e9);
  color: #ffff;
}
.light .section__2,
.light .section__6 .col-lg-12,
.light .section__6 .col-lg-11,
.light .section__7 .col-lg-12,
.light .section__8 .col-lg-12,
.light .section__8 .col-lg-11,
.light .section__11,
.light .section__7 .row_custom {
  background-color: #ffff;
}
.light .color__white {
  color: #fff;
}

.light .home {
  background-color: #f6d4b1;
}
.light .about-text {
  color: #57585a;
}
.light .section__4__client {
  background-color: #0a192f;
  color: #ffff;
}

.light .section__3__about {
  background-color: #0a192f;
  color: #ffff;
}
.light .section__4__client {
  background-color: #ffffff;
  color: #000;
}
.light .section__9__grad {
  color: #ffff;
}

/* dark Colors */
.dark {
  background-color: #202020;
}
.dark .section__11 {
  background-color: #202020;
  color: #ffff;
  border-top: 1px solid rgb(47, 47, 47);
}
.dark .section__2 {
  background-color: #202020;
  border-top: 1px solid rgb(47, 47, 47);
}
.dark .ReactCardFlipFront {
  background-color: #363535;
}
.dark .ReactCardFlipBack {
  background-color: #363535;
}
.dark .section__3 {
  background-color: #202020;
  color: #fff;
  border-top: 1px solid rgb(47, 47, 47);
}
.dark .section__4 {
  background-color: #202020;
  color: #fff;
  border-top: 1px solid rgb(47, 47, 47);
}
.dark .section__4__client__p {
  color: #949496;
}
.dark .section__4__client_s {
  background-color: #363535;
  color: #ffff;
}
.dark .section__5 {
  background: linear-gradient(115deg, #e65160 0%, #a264df 74%);
  color: #ffff;
}
.code {
  background-color: #363535;
  color: #fff;
}
.dark .section__2,
.dark .section__6 .col-lg-12,
.dark .section__6 .col-lg-11,
.dark .section__7 .col-lg-12,
.dark .section__8 .col-lg-12,
.dark .section__7 .row_custom,
.dark .section__11,
.dark .section__8 .row_custom,
.dark .code {
  background-color: #363535;
  color: #fff;
}
.dark .color__white {
  color: #fff;
}

.dark .home {
  background-color: #202020;
  color: #fff;
}
.dark .about-text {
  color: #949496;
}
.dark .section__4__client {
  background-color: #0a192f;
  color: #ffff;
}

.dark .section__3__about {
  background-color: #0a192f;
  color: #ffff;
}
.dark .section__4__client {
  background-color: #363535;
  color: #ffff;
}
.dark .section__9__grad {
  color: #ffff;
}

.section__9__grad h2 {
  color: #ffff !important;
}
.section__10 {
  border-radius: 20px;
}

.section__9__grad:hover {
  transform: scale(1.02, 1.02);
}
