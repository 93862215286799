

.border__footer{
    border-top: 2px #808285 solid;
}

@media only screen and (max-width: 512px) {

    .Footer{
        color: #808285;
        font-weight: 500;
        font-size: 0.9rem;
    }
  }
  @media only screen and (min-width: 512px) {
  
.Footer{
    color:#808285;
    font-weight: 500;
    font-size: 0.8rem;
}
  }