.bg-dark {
  --bs-bg-opacity: 1;
  background-color:  #333333 !important;
}
.bg-dark h6{
    color:#fff;
}
.blue__color{
    color:#006DDB;
    cursor: pointer;
}
.bg-dark li{
    list-style: none;
}
.light .sticky {
    position: -webkit-sticky;
    position: fixed;
    top: 0;
    z-index: 1029;
    -webkit-backdrop-filter: saturate(129%) blur(20px);
    backdrop-filter:saturate(129%) blur(20px);
        border-bottom: 1px solid #e2e2e2;
    background-color: #333333;
    color: #fff;
    padding-block:0.5rem;
}
.dark .sticky {
    padding-block:0.5rem;
    position: -webkit-sticky;
    position: fixed;
    top: 0;
    z-index: 1029;
    -webkit-backdrop-filter: saturate(129%) blur(20px);
    backdrop-filter:saturate(129%) blur(20px);
        border-bottom: 1px solid #3a3a3a;
    background-color: rgba(47, 47, 47, 0.7);
}
.nav-item{
    list-style-type: none;
}